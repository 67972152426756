import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { format } from 'date-fns';

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const ListCategory = () => {

    /* ========================= Product data start =============================== */
    const [categoryList, setcategoryList] = useState([]);
    const [searchstr, setsearchstr] = useState([]);
    const [errorMessage, seterrorMessage] = useState(false);

    const fetchData = () => {
        UserService.getAllCategories().then(
            (response) => {
                console.log(response);
                setcategoryList(response.data.categories);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                //console.log(resMessage);
            }
        );
    }

    useEffect(() => {
        fetchData();
    }, []);

    /* ================================ Search product by Name ==============================*/

    const searchCategoryData = (e) => {
        var data = {
            "query": e.target.value
        };
        //  console.log(data);
        setsearchstr(e.target.value);
        UserService.searchCategoryData(data).then(
            (response) => {
                // console.log(response);
                setcategoryList(response.data.categories);
                if (response.data.categories.length <= 0) {
                    seterrorMessage(true);
                } else {
                    seterrorMessage(false)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // console.log(resMessage);
            }
        );
    };

    /* ================================= Delete Category Data =================================== */
    const deleteCategoryData = (catid, catflag, e) => {
        console.log(catid + '---' + catflag);
        if (window.confirm('Are you sure you want to delete this Category Data?')) {
            // Save it!
            const data = {
                catid: catid,
                catflag: catflag
            };

            UserService.deleteBulkData(data).then(
                (response) => {
                    // console.log(response);
                    window.location.reload();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    // console.log(resMessage);
                }
            );
        } else {
            // Do nothing!
            console.log('Delete operation not occurred!');
        }
    };

    return (
        <>
            <div className="table-container" style={{ display: 'block' }}>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-2 mt-2">Categories</h5>
                    </div>
                    <div className="col text-right">
                        <Link to={`/excelupload/`} className="btn">Upload</Link>
                        <input type="text" name="searchstr" onChange={searchCategoryData} value={searchstr} />
                    </div>
                </div>
                <div className="d-flex text-muted schedul-tbl-box table-responsive">
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th>File Name</th>
                                <th>Category Unique Code</th>
                                <th>Product Count</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {categoryList && categoryList.length > 0 && categoryList.map((categoryListObj, index) => (
                                <tr key={categoryListObj.category._id}>
                                    <td>{categoryListObj.category.name}</td>
                                    <td>{categoryListObj.category.categoryFlag}</td>
                                    <td>{categoryListObj.count}</td>
                                    <td>{format(new Date(categoryListObj.category.createdAt), 'Pp')}</td>
                                    <td>
                                        <ul className="qr-action-area action-menu">
                                            <li>
                                                <Link to={`/category/${categoryListObj.category._id}`} className="dropdown-item"><i className="fa fa-eye" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item text-danger" to="/categories" onClick={e => deleteCategoryData(categoryListObj.category._id, categoryListObj.category.categoryFlag, e)} title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                            {errorMessage && (<tr><td>Category not found!</td></tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default adminLayout(ListCategory);