import React, { useState, useRef } from "react";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const UploadForm = () => {
    const form = useRef();
    const checkBtn = useRef();

    const [files, setFiles] = useState([]); // Store files as an array
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const required = (value) => {
        if (!value) {
            return (
                <div className="invalid-feedback d-block">
                    This field is required!
                </div>
            );
        }
    };

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles(Array.from(selectedFiles)); // Convert FileList to Array
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);
        form.current.validateAll();

        // Prepare FormData to upload files
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("uploadfile", file); // Append each file
        });

        if (checkBtn.current.context._errors.length === 0) {
            UserService.uploaddata(formData).then(
                (response) => {
                    setMessage(response.msg);
                    setLoading(false);
                    setFiles([]); // Clear selected files after successful upload
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="my-5 p-3 bg-body rounded shadow-sm">
                <section id="forms">
                    <article className="my-3" id="sizing">
                        <div className="bd-heading sticky-xl-top align-self-start mt-4 mb-5 mt-xl-0 mb-xl-4">
                            <h3>Upload Product</h3>
                        </div>
                        <Form className="login-form" onSubmit={handleSubmit} ref={form}>
                            <div className="mb-3 col-12">
                                <label className="mb-3">Upload Excel File</label>
                                <input
                                    type="file"
                                    multiple
                                    className="form-control form-control-md"
                                    name="excelupload"
                                    onChange={handleFileChange}
                                    validations={[required]}
                                />
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary btn-md" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Upload Data</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group mt-4">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </article>
                </section>
            </div>
        </>
    );
};

export default adminLayout(UploadForm);
