import React, { useState, useRef } from "react";
import "../../assets/css/login.css"
import { useNavigate } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";
import AuthService from "../../services/auth.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const LoginPage = () => {   

    const required = (value) => {
        if (!value) {
          return (
            <div className="invalid-feedback d-block">
              This field is required!
            </div>
          );
        }
      };
      
      
        const form = useRef();
        const checkBtn = useRef();
      
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState("");
      
        const navigate = useNavigate();
      
        const onChangeEmail = (e) => {
          const email = e.target.value;
          setEmail(email);
        };   
        
        const onChangePassword = (e) => {
          const password = e.target.value;
          setPassword(password);
        }
      
        const handleLogin = (e) => {
          e.preventDefault();
      
          setMessage("");
          setLoading(true);
      
          form.current.validateAll();
      
          if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(email,password).then(
              (data) => { console.log(data);
                if(data.status === 200){
                  if(data.data.data.user.role === 'admin') navigate("/dashboard");
                }else{
                  const resMessage = data.response.data.message;      
                  setLoading(false);
                  setMessage(resMessage);
                }
               // console.log(response.data.user.role);
                //if(response.data.user.role === 'admin') navigate("/dashboard");
               // window.location.reload();

              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                  error.message ||
                  error.toString();
      
                setLoading(false);
                setMessage(resMessage);
              }
            );
          } else {
            setLoading(false);
          }
        };
  
        return <>
            <Form className="login-form" onSubmit={handleLogin} ref={form}>
                <div className="d-flex align-items-center my-4">
                    <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                </div>
                {/* <!-- Email input --> */}
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="emailField">Email</label>
                    <Input 
                    type="email" 
                    id="emailField" 
                    className="form-control form-control-lg"
                    placeholder="Enter a valid email address" 
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required]}
                    />
                </div>  

                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="passwordField">Password</label>
                    <Input 
                    type="password" 
                    id="passwordField" 
                    className="form-control form-control-lg"
                    placeholder="Enter password" 
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required]}
                    />
                </div>    

                <div className="text-center text-lg-start mt-4 pt-2"> 
                    <button className="btn btn-primary btn-lg" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span className="px-2">Login</span>
                    </button>                  
                </div>
                {message && (
                    <div className="form-group mt-3">
                      <div className="alert alert-danger" role="alert">
                          {message}
                      </div>
                    </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
        </>
}

export default authLayout(LoginPage);